.backdrop {
  background-color: var(--named-color-nepal-80);
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: var(--zindex-modal-backdrop);
  opacity: 0.75;
}

@keyframes fade-in {
  from {
    opacity: 0;
  }

  to {
    opacity: 0.75;
  }
}

@keyframes fade-out {
  from {
    opacity: 0.75;
  }

  to {
    opacity: 0;
  }
}

.fadeIn {
  animation: fade-in 0.3s ease-out forwards;
}

.fadeOut {
  animation: fade-out 0.3s ease-out forwards;
}

.container {
  position: fixed;
  z-index: calc(var(--zindex-modal-backdrop) + 1);
  left: 0;
  bottom: 0;
  width: 100vw;
  background: white;
  border-top-left-radius: 15px;
  border-top-right-radius: 15px;
  transition: transform 0.3s ease;
  max-height: 85vh;
  overflow-y: auto;
}

.entering {
  transform: translate3d(0, 100%, 0);
}

.entered {
  transform: none;
}

.exiting {
  transform: translate3d(0, 100%, 0);
}

.exited {
  display: none;
}

.closeButton {
  position: absolute;
  top: 16px;
  right: 16px;
  width: 14px;
  height: 14px;
  cursor: pointer;
  z-index: calc(var(--zindex-modal-backdrop) + 2);
}

.cross {
  color: var(--named-color-navy-80);
  width: 14px;
  height: 14px;
}

.closeButton:hover .cross,
.closeButton:focus .cross {
  animation: rotation 0.4s ease-in-out;
}

@keyframes rotation {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(180deg);
  }
}
