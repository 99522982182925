.container {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  height: 100vh;
  width: 100%;
  background-color: var(--named-color-nepal-20);
  background-image: url('../../assets/images/BackgroundInCosafeColors.svg');
  background-repeat: repeat;
  background-size: cover;
}

@media (width < 768px) {
  .container {
    justify-content: start;
    background-position-x: 98%;
  }
}
