/* stylelint-disable color-function-notation */

.container {
  position: relative;
  display: grid;
  width: 100%;
  height: 450px;
  overflow: hidden;
  background-image: url("../../assets/images/cosafe-mentoring-session.png");
  background-repeat: no-repeat;
  background-position: 10%;
  background-size: cover;
}

.textContainer {
  display: flex;
  flex-direction: column;
  width: 90%;
  align-items: self-start;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
}

.overlay {
  position: relative;
  grid-area: 1 / 1;
  width: 100%;
  height: 100%;
  background: linear-gradient(
    258deg,
    transparent 0%,
    #001932 75%,
    #001932 100%
  ), linear-gradient(
    60deg,
    transparent 80%,
    rgba(255, 170, 67, 20%) 100%
  );
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  padding: 3% 20px 20px 80px;
  box-sizing: border-box;
}

.logoContainer {
  width: 192px;
}

.button {
  margin-top: 1%;
  width: 420px;
  max-width: 420px;
}

.skeleton {
  margin-top: var(--spacing-md);
}

/* Extra-Large width screens */
@media (width > 1441px) {
  .container {
    background-size: 80%;
    background-position-x: right;
    background-position-y: center;
  }

  .container .textContainer > span {
    font-size: 29px;
  }

  .textContainer {
    top: 55% 
  }

  .button {
    max-width: 500px;
    width: 500px;
  }
}


/* Tablet's biggest width */
@media (width < 1380px) {
  .overlay {
    padding: 35px 36px 80px;
  }
}

/* average mobile phones */
@media (width < 768px) {
  .container {
    height: 63vh;
    background-position-x: 78%;
  }

  .cosafeLogo {
    margin-right: 8px;
  }


  .overlay {
    padding: 32px 16px 50px;
     background: linear-gradient(
      280deg,
    transparent 0%,
    #001932 80%,
    #001932 100%
    ), linear-gradient(
      24deg,
      transparent 80%,
      rgba(255, 170, 67, 20%) 100%
    ); 
  }

  .container .textContainer > span {
    font-size: var(--font-size-h3);
  }

  .button {
    margin-top: 12px;
    width: 100%;
  }

  .skeleton {
    max-width: 90vw;
  }
}

/* small mobile phones */
@media (width <= 375px) {
  .logoContainer {
    margin-bottom: 30%;
  }
}
