.container {
  width: 100%;
  height: 100vh;
  background-color: var(--named-color-blue-100);
  display: flex;
  justify-content: center;
  align-items: center;
}

.centeredContent {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
}

.completeIllustration {
  max-width: 100%;
  height: auto;
  margin-bottom: var(--spacing-md);
  animation: illustration-fade-in 1050ms 270ms ease-in-out forwards;
  opacity: 0;
}

.card {
  max-width: 480px;
  width: calc(100% - (var(--spacing-md) * 2));
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: var(--spacing-md);
}

.cardUnderlay {
  position: absolute;
  inset: 0;
  display: flex;
  z-index: -1;
  animation: card-underlay-elastic-shrink 982ms 780ms
    cubic-bezier(0.07, 0.47, 0.04, 0.97) forwards;
  margin-bottom: -120px;
}

.centeredContent > :not(:first-child) {
  transform: translateY(100vh);
  animation: card-slide-in 982ms 780ms cubic-bezier(0, 1, 0.07, 0.98) forwards;
}

.icon {
  color: var(--named-color-green-100);
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  margin: var(--spacing-sm) 0;
}

.courseName {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  margin-bottom: 12px;
}

@keyframes illustration-fade-in {
  to {
    opacity: 1;
  }
}

@keyframes card-slide-in {
  to {
    transform: translateY(0);
  }
}

@keyframes card-underlay-elastic-shrink {
  to {
    margin-bottom: 0;
  }
}

@media (width <= 768px) {
  .card button {
    min-height: 40px;
  }
}
