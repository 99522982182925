.container {
  display: flex;
  height: 40px;
  width: 40px;
  border-radius: var(--border-radius-lg);
  background-color: var(--named-color-white-100);
  cursor: pointer;
  justify-content: center;
  align-items: center;
  border: none;
  z-index: 3;
}
