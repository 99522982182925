.button {
  width: 100%;
  min-height: 44px;
  border-style: none;
  font-family: var(--font-primary);
  font-size: var(--font-size-body);
  font-weight: var(--font-weight-medium);
  cursor: pointer;
  box-sizing: content-box;
  padding: 0;
  border-radius: 0;
  color: var(--color-button-menu-text);
  background-color: var(--button-background-color, var(--color-button-menu-bg));
}

.button:hover {
  color: var(--color-button-menu-hover-text);
  background-color: var(--color-button-menu-hover-bg);
}

.button:active {
  color: var(--color-button-menu-onclick-text);
  background-color: var(--color-button-menu-onclick-bg);
}

.button:disabled {
  cursor: not-allowed;
  color: var(--color-button-menu-disabled-text);
  background-color: var(--color-button-menu-disabled-bg);
}

.button:hover .chevronRightIcon {
  animation: right-shake 0.2s ease-in-out forwards;
}

.button:hover .chevronLeftIcon {
  animation: left-shake 0.2s ease-in-out forwards;
}

@keyframes left-shake {
  0% {
    transform: translateX(0);
  }

  100% {
    transform: translateX(-5px);
  }
}

@keyframes right-shake {
  0% {
    transform: translateX(0);
  }

  100% {
    transform: translateX(5px);
  }
}
