.container {
  display: flex;
  align-items: center;
  padding: var(--spacing-md);
  gap: var(--spacing-md);
}

.between {
  justify-content: space-between;
}

.start {
  justify-content: flex-start;
}

.end {
  justify-content: flex-end;
}

.center {
  justify-content: center;
}

.bottomBorder {
  border-bottom: 1px solid var(--named-color-nepal-20);
}
