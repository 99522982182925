.container {
  display: flex;
  align-items: center;
  justify-content: center;
  min-width: 32px;
  min-height: 32px;
}

.profileCircle {
  width: 32px;
  height: 32px;
  aspect-ratio: 1;
  object-fit: cover;
  border-radius: var(--border-radius-circle);
  color: var(--named-color-navy-60);
}

.profileCircle.photo {
  background-color: var(--named-color-nepal-40);
}
