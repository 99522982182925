.questionContainer {
  margin: var(--spacing-md) 0;
  width: inherit;
}

.moduleFeedbackContainer {
  position: fixed;
  bottom: var(--spacing-sm);
  width: var(--width-video);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: var(--zindex-sticky);
}

.stickyBottomContainer {
  position: sticky;
  bottom: var(--spacing-sm);
  left: 0;
  right: 0;
}

.loaderIcon {
  width: 20px;
  height: 20px;
  padding-right: 2px;
  display: flex;
  justify-content: center;
  align-items: center;
  animation: rotation var(--transition-slow) linear infinite;
}

.stickyBottomContainer button {
  height: 52px;
}

.iconButtonWrapper {
  animation: fade-in 0.3s cubic-bezier(0.4, 0, 0.2, 1) forwards;
}

@keyframes rotation {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}

@keyframes fade-in {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}
