.card {
  width: var(--max-width-course-card);
}

.container {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;
}

.topRightContainer {
  display: flex;
  align-items: center;
  position: absolute;
  top: var(--spacing-sm);
  right: var(--spacing-sm);
}

.badge {
  padding-left: var(--spacing-sm);
}

.textContainer {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  flex-grow: 1;
  width: 100%;
}

.title {
  padding-right: 1em;
}

.infoSetContainer {
  padding: 5px 0;
}

.description {
  margin-top: 5px;
}

.buttonContainer {
  display: flex;
  justify-content: center;
  align-items: flex-end;
  width: 100%;
}

@media (orientation: portrait) {
  .card {
    max-width: unset;
  }
}
