.sidebar {
  width: 100%;
  height: 100vh;
  background-color: var(--named-color-nepal-5);
  border-right: var(--border);
}

.sidebarContent {
  display: flex;
  flex-direction: column;
  height: 100%;

  ::-webkit-scrollbar {
    width: 6px;
  }

  ::-webkit-scrollbar-track {
    background: transparent;
  }

  ::-webkit-scrollbar-thumb {
    background: var(--named-color-nepal-100);
    border-radius: 8px;
  }
}

.headerLogo {
  margin: var(--spacing-lg) var(--spacing-md);
  padding-right: var(--spacing-md);
}

.content {
  flex: 1;
  display: flex;
  flex-direction: column;
  overflow: hidden;
}

.courseTitle {
  margin: var(--spacing-lg) var(--spacing-md);
}

.steps {
  flex: 1;
  overflow-y: auto;
  padding: 0 0 0 var(--spacing-md);
}

.footer {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: var(--spacing-lg) var(--spacing-md);
}

.mobileFooter {
  display: none;
}

.userCard {
  width: 190px;
  margin-right: 5px;
}

.innerRow {
  display: flex;
  direction: row;
  align-items: center;
}

.flag {
  width: 20px;
  height: 20px;
  border-radius: var(--border-radius-lg);
  margin-right: var(--spacing-md);
}

.loaderIcon {
  width: 20px;
  height: 20px;
  padding-right: 2px;
  display: flex;
  justify-content: center;
  align-items: center;
  animation: rotation var(--transition-slow) linear infinite;
}

.loaderSteps > div {
  margin-bottom: var(--spacing-lg);
}

@media (width < 768px) {
  .footer {
    display: none;
  }

  .headerLogo {
    display: none;
  }

  .courseTitle {
    margin: 0.75rem var(--spacing-md);
    line-height: 1.5;
  }

  .steps {
    margin-top: var(--spacing-md);
  }

  .mobileFooter {
    display: flex;
    flex-direction: column;
  }

  .mobileFooterSeparator {
    height: 1px;
    background-color: var(--named-color-nepal-20);
  }

  .sidebarContent {
    animation: slide-in 200ms forwards;
  }

  .close {
    animation: slide-out 200ms forwards;
  }
}

@keyframes slide-in {
  from {
    transform: translateX(-100%);
    opacity: 0;
  }

  to {
    transform: translateX(0);
    opacity: 1;
  }
}

@keyframes slide-out {
  from {
    transform: translateX(0);
    opacity: 1;
  }

  to {
    transform: translateX(-100%);
    opacity: 0;
  }
}

@keyframes rotation {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}
