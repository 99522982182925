.hamburgerIcon,
.crossIcon {
  overflow: visible;
}

.hamburgerIcon path {
  transition: opacity 0.3s ease-in-out;
}

.animateHamburgerIn path {
  animation: hamburger-in 0.3s ease-in-out forwards;
}


.hamburgerMenuContainer:hover .crossIcon,
.hamburgerMenuContainer:focus .crossIcon {
  animation: rotation 0.4s ease-in-out;
}

.hamburgerMenuContainer:hover {
  background-color: var(--named-color-lightgrey-80);
}


.animateCrossOut path:nth-child(1) {
  animation: cross-first-line-out 0.3s ease-out forwards;
}

.animateCrossOut path:nth-child(2) {
  animation: cross-last-line-out 0.3s ease-out forwards;
}


.animateHamburgerOut path:nth-child(1) {
  animation: hamburger-first-strip-out 0.3s ease-out forwards;
}

.animateHamburgerOut path:nth-child(2) {
  opacity: 0;
}

.animateHamburgerOut path:nth-child(3) {
  animation: hamburger-last-strip-out 0.3s ease-out forwards;
}

@media (width > 767px) {
  .hamburgerMenuContainer {
    display: none;
  }
}

@keyframes rotation {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(180deg);
  }
}

@keyframes hamburger-first-strip-out {
  0% {
    transform: rotate(0) translate(0, 0);
  }

  30% {
    transform: translateY(-2px);
  }

  90% {
    transform-origin: left;
    animation-timing-function: cubic-bezier(0.34, 1.56, 0.64, 1);
    transform: translate(-2px, -2px) rotate(45deg);
  }

  100% {
    opacity: 0;
  }
}

@keyframes hamburger-last-strip-out {
  0% {
    transform: rotate(0) translate(0, 0);
    transform-origin: top-left; /* rotate from top right */
  }

  30% {
    animation-timing-function: cubic-bezier(0.34, 1.56, 0.64, 1);
    transform: translateY(2px);
  }

  90% {
    transform-origin: left;
    transform: translate(-2px, 0) rotate(-45deg);
  }

  100% {
    opacity: 0;
  }
}

@keyframes hamburger-in {
  0% {
    opacity: 0;
    transform: translateX(8px);
  }

  100% {
    transform: translateX(0);
    opacity: 1;
  }
}

@keyframes cross-first-line-out {
  0% {
    transform: rotate(0) translate(0, 0);
  }

  90% {
    transform-origin: left;
    transform: scaleX(0.8) translateX(8px) rotate(-45deg);
  }

  100% {
    opacity: 0;
  }
}

@keyframes cross-last-line-out {
  0% {
    transform: rotate(0) translate(0, 0);
  }

  90% {
    transform-origin: left;
    transform: scaleX(0.8) translateX(8px) rotate(45deg);
  }

  100% {
    opacity: 0;
  }
}
