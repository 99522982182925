.headerContainer {
  margin-bottom: 12px;
}

.divider {
  height: 1px;
  background-color: var(--named-color-nepal-20);
  margin: 16px 0;
}

.icon {
  height: 16px;
}

.icon.attention {
  color: var(--color-attention);
}

@media (width < 768px) {
  .headerContainer {
    margin-bottom: var(--spacing-md);
  }

  .paddingContainer {
    margin-bottom: 13px;
  }
}

.buttonsContainer {
  display: flex;
  justify-content: stretch;
  flex-direction: column;
  gap: var(--spacing-md);
  width: 100%;
  margin-top: var(--spacing-md);
  margin-bottom: var(--spacing-md);
}
