.wrapper {
  position: relative;
  min-width: var(--min-width-user-card);
  max-width: var(--max-width-user-card);
}

.container {
  width: 100%;
  height: var(--height-card-small);
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: var(--spacing-md);
  padding: 0 10px;
  background-color: var(--named-color-white-100);
  border-radius: var(--border-radius-lg);
  cursor: pointer;
}

.container.bordered {
  border: var(--border-thick);
}

.container.expanded {
  background-color: var(--color-button-bare-onclick-bg);
}

.expandIconContainer {
  color: var(--named-color-navy-60);
  display: flex;
  align-items: center;
  margin-right: var(--spacing-sm);
  margin-left: auto;
}

.expandIcon {
  width: 16px;
  height: 8px;
}

.staticMenu {
  position: absolute;
  left: 0;
  right: 0;
  z-index: 2;
  opacity: 0;
  pointer-events: none;
  transition-duration: 175ms;
  transition-timing-function: cubic-bezier(0.165, 0.84, 0.44, 1);
}

.staticMenu.down {
  top: 70%;
}

.staticMenu.up {
  top: -70%;
}

.staticMenu.expanded {
  opacity: 1;
  pointer-events: all;
}

.staticMenu.expanded.down {
  top: 110%;
}

.staticMenu.expanded.up {
  top: -110%;
}
