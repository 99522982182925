.container {
  position: relative;
  height: 0;
  overflow: hidden;
  border-radius: var(--border-radius-lg);
  border: none;
}

.videoFrame {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border-radius: var(--border-radius-lg);
  border: none;
}

.loom {
  padding-bottom: 64.6108%;
}

.youtube {
  padding-bottom: 56.25%;
}
