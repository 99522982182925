.button {
  width: 250px;
  min-height: 44px;
  border-radius: 8px;
  border-style: none;
  font-family: var(--font-primary);
  font-size: var(--font-size-body);
  font-weight: var(--font-weight-medium);
  cursor: pointer;
  box-sizing: content-box;
  padding: 0;
  transition-duration: 125ms;
  transition-timing-function: ease-out;
}

.button:disabled {
  cursor: not-allowed;
}

.button.primary {
  color: var(--color-button-primary-text);
  background-color: var(--color-button-primary-bg);
}

.button.primary:hover {
  background-color: var(--color-button-primary-hover-bg);
  color: var(--color-button-primary-hover-text);
  transition-duration: 75ms;
}

.button.primary:active {
  background-color: var(--color-button-primary-onclick-bg);
  color: var(--color-button-primary-onclick-text);
}

.button.primary:disabled {
  background-color: var(--color-button-primary-disabled-bg);
  color: var(--color-button-primary-disabled-text);
}

.button.secondary {
  color: var(--color-button-secondary-text);
  background-color: var(
    --button-background-color,
    var(--color-button-secondary-bg)
  );
}

.button.secondary:hover {
  color: var(--color-button-secondary-hover-text);
  background-color: var(--color-button-secondary-hover-bg);
}

.button.secondary:active {
  color: var(--color-button-secondary-onclick-text);
  background-color: var(--color-button-secondary-onclick-bg);
}

.button.secondary:disabled {
  color: var(--color-button-secondary-disabled-text);
  background-color: var(--color-button-secondary-disabled-bg);
}

.button.bare {
  color: var(--color-button-bare-text);
  background-color: var(--button-background-color, var(--color-button-bare-bg));
}

.button.bare:hover {
  color: var(--color-button-bare-hover-text);
  background-color: var(--color-button-bare-hover-bg);
}

.button.bare:active {
  color: var(--color-button-bare-onclick-text);
  background-color: var(--color-button-bare-onclick-bg);
}

.button.bare:disabled {
  color: var(--color-button-bare-disabled-text);
  background-color: var(--color-button-bare-disabled-bg);
}

button.bare.attention,
button.bare.attention:hover,
button.bare.attention:active {
  color: var(--color-button-attention-text);
}

button.bare.attention:disabled {
  color: var(--color-button-attention-text-light);
}

.button.full {
  width: 100%;
}

.button.fullAuto {
  width: 100%;
  height: auto;
}

.iconContainer {
  vertical-align: middle;
  display: inline;
  padding-right: 8px;
}

.iconContainer svg {
  vertical-align: middle;
}

.textContainer {
  vertical-align: middle;
  display: inline;
}

@media (width < 768px) {
  .button {
    width: 150px;
  }
}

@media (width < 576px) {
  .button {
    width: 80px;
  }
}
