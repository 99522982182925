.wrapper { 
  animation: slide-in 0.3s cubic-bezier(0,.56,.47,.72) forwards;
}

.container {
  border: var(--border);
  border-radius: var(--border-radius-lg);
  width: 100%;
  position: relative;
  background-color: var(--named-color-green-40);
}

.wrapper.close {
  animation: slide-out 0.3s cubic-bezier(0,.56,.47,.72) forwards;
}

.container.moduleNotCompleted {
  background-color: var(--named-color-blue-40);
}

.contentContainer {
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 100%;
  padding: var(--spacing-md);
}

.progressContainer {
  width: 48px;
  margin-right: 12px;
}

.textContainer {
  display: flex;
  flex-direction: column;
  justify-content: center;
  flex-grow: 1;
  flex-shrink: 1;
}

.buttonContainer {
  margin-left: var(--spacing-md);
  flex-grow: 1;
  flex-shrink: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  min-width: 150px;

  --button-background-color: var(--named-color-white-100);
}

.confetti { 
  position: absolute;
  top: -70px;
  right: -70px; 
}

@media (width < 768px) {
  .contentContainer {
    flex-direction: column;
    align-items: start;
  }

  .progressContainer {
    flex: 0;
    margin-bottom: 12px;
    width: 48px;
  }

  .textContainer {
    margin-bottom: 12px;
  }

  .buttonContainer {
    margin: 0;
    min-width: unset;
  }

  .buttonContainer button {
    padding: 0 var(--spacing-lg);
    min-height: 36px;
    height: 36px;
  }
}


@keyframes slide-in {
  from {
    transform: translateY(100%);
    opacity: 0;
  }

  to{
    transform: translateY(0);
    opacity: 1;
  }
}

@keyframes slide-out {
  0% {
    transform: translateY(0);
    opacity: 1;
  }

  100% {
    transform: translateY(100%);
    opacity: 0;
  }
}
