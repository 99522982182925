.container {    
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    background-color: var(--color-backdrop-bg);
    z-index:  var(--zindex-modal-backdrop);
    opacity: 0.7;
    display: flex;
    justify-content: center;
    align-items: center;
}

.icon {
    width: 45px;
    height: 45px;
    padding-right: 6px;
    display: flex;
    justify-content: center;
    align-items: center;
    animation: rotation var(--transition-slow) linear infinite;

}

@keyframes rotation {
    0% {
        transform: rotate(0deg);
    }

    100% {
        transform: rotate(360deg);
    }
} 
