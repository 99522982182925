.container {
  position: relative;
  overflow: hidden;
  border-radius: var(--border-radius-lg);
  border: none;
}

.thumbnailContainer {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.thumbnailBackgroundImage {
  position: absolute;
  border-radius: var(--border-radius-lg);
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
}

.thumbnail {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border-radius: var(--border-radius-lg);
  border: none;
}

.thumbnailOverlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border-radius: var(--border-radius-lg);
  background: var(--color-background-overlay);
  border: none;
}

.playButtonContainer {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.playButtonContainer button {
  width: 100%;
  padding: 0 var(--spacing-md);
}
