.selectedLanguage {
  padding: var(--spacing-md);
  display: flex;
  direction: row;
  align-items: center;
  justify-content: space-between;
  cursor: pointer;
  border-bottom: 1px solid var(--named-color-nepal-40);
}

.innerRow {
  display: flex;
  direction: row;
  align-items: center;
}

.languageListItem {
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: var(--spacing-md);
  gap: var(--spacing-md);
  cursor: pointer;
  background-color: var(--named-color-white-100);
}

.languageSelected {
  background-color: var(--named-color-lightgrey-120);
}

.icon {
  margin-right: var(--spacing-md);
}

.flag {
  width: 20px;
  height: 20px;
  border-radius: var(--border-radius-lg);
}
