.container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.childContainer {
  padding: var(--spacing-md) var(--spacing-md) var(--spacing-sm);
}

.link {
  width: 100%;
  text-align: right;
  padding: var(--spacing-sm) 0;
}

.button {
  text-align: center;
  width: 100%;
  margin-top: var(--spacing-sm);
}

@media (width <= 768px) {
  .container {
    height: auto;
    padding-top: 100px;
  }
}
