.header {
  width: 100%;
  height: 28px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: var(--spacing-md);
}

.languageSelector {
  display: flex;
  justify-content: flex-end;
  align-items: center;
}
