.modal {
  width: 100%;
  height: 1vh;
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  background-color: var(--named-color-nepal-40);
  padding-top: 112px;
  z-index: 100;
  display: none;
  flex-direction: column;
}

.modal.open {
  display: flex;
  height: 100vh;
  animation: slide-down 0.5s ease-out forwards;
}

.modal.open::before {
  content: '';
  position: absolute;
  top: 100%;
  left: 50%;
  width: 50%;
  height: 10%;
  background-color: var(--named-color-nepal-40);
  animation: after-slide-down 0.2s ease-in-out;
}

.modal.open::after {
  content: '';
  position: absolute;
  top: 100%;
  left: 0;
  width: 50%;
  height: 10%;
  background-color: var(--named-color-nepal-40);
  animation: after-slide-down 0.5s ease-in-out;
}

.modal.close {
  display: flex;
  animation: slide-up 0.5s ease-out forwards;
}

.showMainButtons {
  animation: slide-from-far-to-right 0.3s cubic-bezier(0.25, 0.8, 0.25, 1);
}

.languageSelector {
  animation: slide-right 0.3s cubic-bezier(0.25, 0.8, 0.25, 1) forwards;
}

.flag {
  width: 20px;
  height: 20px;
  border-radius: var(--border-radius-lg);
  margin-right: var(--spacing-md);
}

.loaderIcon {
  width: 20px;
  height: 20px;
  padding-right: 2px;
  display: flex;
  justify-content: center;
  align-items: center;
  animation: rotation var(--transition-slow) linear infinite;
}

@media (width > 767px) {
  .modal,
  .modal.open {
    display: none;
  }
}

.logoContainer {
  width: 192px;
  position: fixed;
  margin: 32px 16px 50px;
  top: 0;
  left: 0;
  right: 0;
}

@keyframes after-slide-down {
  0% {
    height: 0;
  }

  100% {
    height: 30%;
  }
}

@keyframes slide-down {
  0% {
    height: 0;
    opacity: 0;
  }

  50% {
    opacity: 1;
  }

  100% {
    height: 100vh;
  }
}

@keyframes slide-up {
  0% {
    height: 100vh;
    opacity: 1;
  }

  100% {
    height: 0;
    opacity: 0;
  }
}

@keyframes after-slide-up {
  0% {
    height: 30%;
  }

  100% {
    height: 0;
  }
}

@keyframes slide-from-far-to-right {
  from {
    transform: translateX(-100%);
    opacity: 0;
  }

  to {
    transform: translateX(0);
    opacity: 1;
  }
}

@keyframes slide-right {
  from {
    transform: translateX(100%);
    opacity: 0;
  }

  to {
    transform: translateX(0);
    opacity: 1;
  }
}

@keyframes rotation {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}
