.container {
  display: flex;
  flex-direction: row;
  align-items: stretch;
  background-color: var(--named-color-nepal-10);
}

.mobileHeader {
  display: none;
  margin-top: var(--spacing-md);
  padding: 0 var(--spacing-md);
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}

.mobileHeaderLogo {
  height: 24px;
  margin-right: var(--spacing-sm);
}

.hamburgerMenuContainer {
  display: flex;
  height: 40px;
  width: 40px;
  border-radius: var(--border-radius-lg);
  background-color: var(--named-color-white-100);
  cursor: pointer;
  justify-content: center;
  align-items: center;
  border: none;
  z-index: 3 !important;
}

.sidebar {
  width: 270px;
  flex-shrink: 0;
}

.courseModule {
  flex-grow: 1;
  overflow-y: auto;
  height: 100vh;
}

.progressBar {
  flex-grow: 1;
  background-color: var(--color-background-dark);
  overflow: hidden;
  height: 8px;
}

.progress {
  background-color: var(--color-attention);
  height: 100%;
}

.curtain {
  background-color: var(--named-color-blue-100);
  position: absolute;
  z-index: var(--zindex-modal-backdrop);
  inset: 0;
  pointer-events: none;
  opacity: 0;
}

.animationTransitionComplete {
  animation: curtain-fade-in 350ms ease-in forwards;
}

button.menuBtn {
  display: none;
}

@media (width < 768px) {
  .displayedSidebarOverlay {
    position: absolute;
    inset: 0;
    z-index: 2;
    background-color: var(--named-color-nepal-80);
    opacity: 0;
  }

  .container {
    flex-direction: column;
  }

  .mobileHeader {
    display: flex;
  }

  .sidebar {
    display: none;
    width: 240px;
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    z-index: 3;
    animation: slide-in 0.5s forwards;
  }

  .openOverlay {
    animation: fade-in 0.5s ease-out forwards;
  }

  .openSidebar {
    display: flex;
  }

  .closeSidebar {
    animation: slide-out 0.5s forwards;
  }

  .mobileSidebarDisplayed {
    display: flex;
  }

  .closeOverlay {
    animation: fade-out 0.5s ease-in forwards;
  }

  .overlayHidden {
    display: none;
  }

  .topContainer {
    background-color: var(--named-color-nepal-10);
    z-index: 2;
    position: sticky;
    top: 0;
    padding-bottom: var(--spacing-sm);
    border-bottom-left-radius: var(--border-radius-lg);
    border-bottom-right-radius: var(--border-radius-lg);
  }

  .hiddenTopContainer {
    z-index: 1;
  }

  button.menuBtn {
    display: flex;
    position: fixed;
    right: var(--spacing-md);
    top: var(--spacing-lg);
    z-index: 4;
  }
}

@keyframes curtain-fade-in {
  to {
    opacity: 1;
  }
}

@keyframes slide-in {
  from {
    transform: translateX(-100%);
    opacity: 0;
  }

  to {
    transform: translateX(0);
    opacity: 1;
  }
}

@keyframes slide-out {
  from {
    transform: translateX(0);
    opacity: 1;
  }

  to {
    transform: translateX(-100%);
    opacity: 0;
  }
}

@keyframes fade-in {
  from {
    opacity: 0;
  }

  to {
    opacity: 0.75;
  }
}

@keyframes fade-out {
  from {
    opacity: 0.75;
  }

  to {
    opacity: 0;
    display: none;
  }
}
