.container {
  flex: 1;
  display: flex;
  flex-direction: column;
  overflow-y: auto;
  max-height: 100%;
}

.step {
  display: flex;
  flex-direction: row;
  align-items: center;
  color: var(--color-text-discrete);
  cursor: pointer;
  margin-right: var(--spacing-md);
}

.step.active {
  color: var(--color-attention);
  cursor: default;
}

.divider {
  width: 2px;
  height: 30px;
  background-color: var(--color-divider);
  margin-left: 10px;
  margin-bottom: 5px;
}

.icon {
  width: 24px;
  height: 24px;
  flex-shrink: 0;
}

.icon.completed {
  color: var(--color-success);
}

.textContainer {
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin-left: var(--spacing-sm);
  flex: 1;
}

.textContainer > * {
  transition-duration: 150ms;
  transition-timing-function: cubic-bezier(0.23, 1, 0.32, 1);
}
