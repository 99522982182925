.container {
  width: 100%;
  display: flex;
  align-items: center;
  position: relative;
  z-index: 0;
  transition-duration: 125ms;
}

.label {
  z-index: 1;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  gap: 8px;
}

.checkboxContainer {
  display: flex;
  align-items: center;
  flex-shrink: 0;
}

.textContainer {
  display: flex;
  align-items: center;
  flex-grow: 1;
  gap: 8px;
}

.highlightUnderlay {
  pointer-events: none;
  z-index: -1;
  position: absolute;
  inset: 0;
  margin: calc(-0.5 * var(--border-radius-sm))
    calc(-1 * var(--border-radius-sm));
  border-radius: var(--border-radius-sm);
  transition-duration: 125ms;
  transition-timing-function: ease-out;
}

.label:not(.disabled) {
  cursor: pointer;
}

.label:not(.disabled):hover .highlightUnderlay {
  background-color: var(--color-button-bare-hover-bg);
}

.label:not(.disabled):active {
  transform: translateY(1px);
}

.checkbox {
  appearance: none;
  width: 24px;
  height: 24px;
  border-radius: var(--border-radius-sm);
  outline: none;
  cursor: pointer;
  position: relative;
  margin-left: 0;
  flex-shrink: 0;
}

.checkbox.small {
  width: 20px;
  height: 20px;
}

.checkbox::before {
  content: '';
  display: block;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: var(--named-color-white-100);
  border: 2px solid var(--named-color-nepal-60);
  border-radius: inherit;
}

.checkbox.right {
  margin-left: auto;
  margin-right: 0;
}

.label.disabled,
.checkbox:disabled {
  cursor: default;
}

.checkbox:checked::before {
  background-color: var(--named-color-yellow-100);
  border-color: var(--named-color-yellow-100);
}

.checkbox:checked:disabled::before {
  background-color: var(--named-color-yellow-20);
  border-color: var(--named-color-yellow-20);
}

.checkbox.danger::before,
.checkbox.danger:checked::before {
  background-color: var(--color-danger);
  border-color: var(--color-danger);
}

.checkbox.success::before {
  border-color: var(--color-success-dark);
}

.checkbox.success:checked::before {
  background-color: var(--color-success-dark);
  border-color: var(--color-success-dark);
}

.checkboxIcon {
  position: absolute;
  top: 50%;
  pointer-events: none;
  display: none;
  color: var(--named-color-white-100);
}

.checkboxIcon.left {
  transform: translate(-50%, -50%);
  left: 12px;
}

.checkboxIcon.left.small {
  left: 10px;
}

.checkboxIcon.right {
  transform: translate(50%, -50%);
  right: 12px;
}

.checkboxIcon.right.small {
  right: 10px;
}

.checkbox:checked + .checkboxIcon {
  display: block;
}
