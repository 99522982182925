.modalsContainer {
  position: fixed;
  inset: 0;
  pointer-events: none;
  display: flex;
  flex-direction: column;
}

.snacksList {
  pointer-events: all;
  align-self: center;
  margin-top: 72px;
  display: flex;
  flex-direction: column;
  gap: var(--spacing-sm);
}
