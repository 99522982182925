.container {
  background-color: var(--named-color-white-100);
  border: var(--border);
  border-radius: var(--border-radius-md);
  width: 100%;
  position: relative;
  height: 100%;
  display: flex;
  flex-direction: column;
}

.container.darkBorder {
  border-color: var(--named-color-nepal-60);
}

.container.noBorder {
  border: none;
}

.container.disabled::before {
  content: '';
  position: absolute;
  top: 0;
  width: 100%;
  height: 100%;
  background-color: var(--color-disabled-overlay);
  z-index: 2;
}

.container.clickable {
  cursor: pointer;
  transition-duration: 240ms;
  transition-timing-function: ease-in-out;
}

.container.clickable:hover {
  background-color: var(--named-color-nepal-20);
  transition-duration: 120ms;
}

.image {
  border-top-left-radius: var(--border-radius-md);
  border-top-right-radius: var(--border-radius-md);
  height: var(--height-card-image);
  width: 100%;
}

.childContainer {
  padding: var(--spacing-md);
  height: 100%;
}

@media (width < 768px) {
  .container {
    width: 100%;
  }
}
