.container {
  max-width: var(--width-video);
  margin-left: auto;
  margin-right: auto;
  padding: 0 var(--spacing-lg);
}

.headerContainer {
  margin-top: var(--spacing-lg);
  display: flex;
  align-items: center;
  gap: var(--spacing-md);
  margin-bottom: var(--spacing-md);
  text-align: left;
}

.headerTitleContainer {
  display: grid;
  align-items: center;
}

.headerContainer a {
  display: flex;
  align-items: center;
}

.videoContainer,
.textContainer,
.questionContainer {
  width: 100%;
  text-align: center;
  margin-bottom: var(--spacing-md);
}

.textContainer {
  height: 300px;
}

.loaderContainer > div,
.loaderContainer > div > div {
  width: 100%;
  height: 320px;
}

@media (width < 768px) {
  .headerContainer {
    gap: var(--spacing-md);
    margin-top: var(--spacing-sm);
  }

  .headerContainer span {
    font-size: var(--font-size-body);
  }

  .headerContainer .headerTitle span {
    font-size: var(--font-size-h3);
  }

  .container {
    padding: 0 var(--spacing-md);
  }
}
