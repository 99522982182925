.row {
  display: flex;
  height: 25px;
  gap: 8px;
  align-items: center;
  margin: var(--spacing-xs) 0;
}

.icon {
  color: var(--named-color-navy-40);
  width: 20px;
  height: 20px;
  display: inline-block;
  vertical-align: middle;
}
