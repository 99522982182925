.container {
  z-index: 999;
  padding: var(--spacing-md);
  border-radius: var(--border-radius-lg);
  display: flex;
  align-items: center;
  justify-content: space-between;
  border: 1px solid var(--named-color-nepal-20);
  width: 336px;
  max-width: 80vw;
  animation: fade-in 0.5s ease-out forwards;
}

.closed {
  animation: fade-out 0.5s ease-in forwards;
}

.innerRow {
  display: flex;
  align-items: center;
  gap: 12px;
}

.closeIcon {
  transform: scale(0.75);
}

.closeIconContainer {
  cursor: pointer;
  height: 18px;
  width: 18px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.success {
  background-color: var(--named-color-light-blue-green);
}

.info {
  background-color: var(--named-color-blue-100);
}

.error {
  background-color: var(--color-warning);
}

.error span,
.error svg,
.info span,
.info svg {
  color: var(--named-color-white-100);
}

@keyframes fade-in {
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}

@keyframes fade-out {
  from {
    opacity: 1;
  }

  to {
    opacity: 0;
  }
}
