.instructionContainer {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.instructionContainer.success {
  color: var(--color-success-dark);
}

.instructionContainer.danger {
  color: var(--color-danger);
}

.checkbox {
  margin: var(--spacing-sm) 0;
}

.icon {
  width: 24px;
  height: 24px;
  animation: fade-in 0.3s cubic-bezier(0.4, 0, 0.2, 1) forwards;
}


@keyframes fade-in {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

