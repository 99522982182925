.container {
  display: flex;
  align-items: center;
  position: relative;
  width: 100%;
  height: 40px;
  border: 1px solid var(--named-color-nepal-60);
  border-radius: var(--border-radius-md);
  padding: var(--spacing-sm);
  background-color: var(--named-color-white-100);
}

.container:hover {
  border-color: var(--named-color-nepal-100);
}

.container.disabled {
  background-color: var(--named-color-black-5);
  border-color: var(--named-color-nepal-60);
}

.container:focus-within {
  border: 2px solid var(--named-color-yellow-100);
  padding: calc(var(--spacing-sm) - 1px);
}

.container.errorField {
  border: 1px solid var(--color-warning);
}

.container.errorField:hover {
  border-color: var(--named-color-red-120);
}

.container.errorField:focus-within {
  border: 2px solid var(--color-warning);
  padding: calc(var(--spacing-sm) - 1px);
}

.label {
  padding-top: 10px;
  padding-left: 12px;
}

.input {
  width: 100%;
  border: none;
  font-family: var(--font-primary);
  font-weight: var(--font-weight-regular);
  font-size: var(--font-size-body);
  color: var(--color-text);
  box-shadow: none;
  outline: none;
}

.input:disabled {
  cursor: not-allowed;
}

input::placeholder {
  color: var(--color-text-help);
}

.icon {
  color: var(--named-color-navy-40);
  height: 15px;
  width: auto;
  margin-right: 5px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.icon.mediumDark {
  color: var(--named-color-navy-60);
}

.icon.dark {
  color: var(--named-color-navy-80);
  height: 12px;
}

.errorText {
  padding-top: 0;
  padding-left: 12px;
}
