.skeletonContainer {
  width: fit-content;
}

.contentOverlayContainer {
  position: absolute;
  inset: 0;
  z-index: 2;
}

.variantContainer {
  position: relative;
  overflow: hidden;
}

.loadingOverlay {
  position: absolute;
  inset: 0;
  background-color: var(--named-color-nepal-40);
}

.loadingGlareMover {
  position: absolute;
  inset: 0;
  transform: translateX(-120%);
  animation: loading-glare-slide 1.18s linear infinite;
}

.loadingGlare {
  position: absolute;
  inset: 0;
  max-width: var(--min-width-card);
  background-image: linear-gradient(
    90deg,
    rgba(255 255 255 / 4%) 0%,
    rgba(255 255 255 / 54%) 45%,
    rgba(255 255 255 / 54%) 55%,
    rgba(255 255 255 / 4%) 100%
  );
  background-size: contain;
}

.loadingOverlay > div {
  position: absolute;
  inset: 0;
}

@keyframes loading-glare-slide {
  to {
    transform: translateX(190%);
  }
}

.cardVariant > div {
  width: 100%;
  height: 100%;
}

.cardVariant {
  width: var(--min-width-card);
  height: var(--height-card-image);
  border-radius: var(--border-radius-md);
}

.courseCardVariant {
  width: var(--max-width-course-card);
  height: unset !important;
}

.textVariant {
  white-space: pre;
  border-radius: var(--border-radius-sm);
}

.avatarVariant {
  border-radius: var(--border-radius-circle);
}

@media (width < 768px) or (orientation: portrait) {
  .skeletonContainer,
  .cardVariant {
    max-width: unset;
    width: 100%;
  }

  .textVariant,
  .avatarVariant {
    width: fit-content;
  }
}

@media (width < 1550px) {
  .courseCardVariant {
    width: unset;
  }
}
