.badge {
  display: inline-flex;
  align-items: center;
  padding: var(--spacing-xs) var(--spacing-md);
  border-radius: var(--border-radius-lg);
}

.badge.light {
  background-color: var(--named-color-white-100);
}

.badge.dark {
  background-color: var(--color-badge-academy);
}
