.container {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
}

.circularProgress {
  transform: rotate(90deg);
  border-radius: 50%;
  background-color: var(--named-color-white-100);
  color: var(--named-color-blue-100);
}

.circularProgress.success {
  color: var(--named-color-green-100);
}

.circularProgress.fail {
  color: var(--named-color-blue-100);
}

.textContainer {
  position: absolute;
  text-align: center;
  width: 100%;
}
