.container {
  font-family: var(--font-primary);
  font-size: var(--font-size-body);
  font-weight: var(--font-weight-regular);
  color: var(--color-text);
  text-align: left;
}

/* Font Weight Variations */
.bold {
  font-weight: var(--font-weight-bold);
}

.medium {
  font-weight: var(--font-weight-medium);
}

.regular {
  font-weight: var(--font-weight-regular);
}

/* Font Size Variations */
.h1 {
  font-size: var(--font-size-h1);
  color: var(--color-text-dark);
}

.h2 {
  font-size: var(--font-size-h2);
  color: var(--color-text-dark);
}

.h3 {
  font-size: var(--font-size-h3);
  color: var(--color-text-dark);
}

.titleLarge {
  font-size: var(--font-size-title-large);
}

.titleSmall {
  font-size: var(--font-size-title-small);
}

.bodyLarge {
  font-size: var(--font-size-body-large);
}

.body {
  font-size: var(--font-size-body);
}

.label {
  font-size: var(--font-size-label);
  text-transform: uppercase;
}

/* Text Align */
.center {
  text-align: center;
}

.right {
  text-align: right;
}

.left {
  text-align: left;
}

/* Font Style Variations */
.italic {
  font-style: italic;
}

/* Variant (Color) Variations */
.success {
  color: var(--color-success-dark);
}

.danger {
  color: var(--color-danger);
}

.attention {
  color: var(--color-attention);
}

.discrete {
  color: var(--color-text-discrete);
}

.complementary {
  color: var(--color-text-complementary);
}

.help {
  color: var(--color-text-help-dark);
}

.primary {
  color: var(--color-button-primary-text);
}

.highlight {
  color: var(--color-text-highlight);
}

/* Truncation Styles */
.truncate {
  overflow: hidden;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  white-space: normal;
}

.truncate.oneLine {
  line-clamp: 1;
  -webkit-line-clamp: 1;
}

.truncate.twoLines {
  line-clamp: 2;
  -webkit-line-clamp: 2;
}

.truncate.tenLines {
  line-clamp: 10;
  -webkit-line-clamp: 10;
}
