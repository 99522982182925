/* stylelint-disable length-zero-no-unit */
.imageContainer {
  display: flex;
  justify-content: center;
  align-items: center;
  max-width: 100%;
  overflow: hidden;
  cursor: pointer;
  border-radius: var(--border-radius-lg);
  border: 1px solid var(--color-border);
  box-shadow: 0px 0px 5px 0px #0000000d;
  position: relative;
}

.containerPlaceHolder {
  height: 300px;
  max-width: 100%;
  border-radius: var(--border-radius-lg);
  border: 1px solid var(--color-border);
  box-shadow: 0px 0px 5px 0px #0000000d;
}

.expandedOverlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background-color: var(--color-background-overlay);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 9999;
  cursor: pointer;
  overflow: hidden;
  opacity: 0;
}

.fadeIn {
  animation: fade-in 0.3s ease-out forwards;
}

.fadeOut {
  animation: fade-out 0.3s ease-out forwards;
}

.image {
  display: block;
  max-width: 100%;
  max-height: 45vh;
  object-fit: contain;
  margin: 0 auto;
}

.expandedImage {
  max-height: 90vh;
  max-width: 90vw;
  width: auto;
  height: auto;
  transition: transform 0.3s ease;
  cursor: zoom-in;
  border-radius: var(--border-radius-lg);
}

.zoomedLevel1 {
  transform: scale(1.2);
}

.zoomedLevel2 {
  transform: scale(1.5);
}

.lastZoomLevel {
  cursor: zoom-out;
}

.expandButton {
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  bottom: 0;
  right: 0;
  margin: 12px;
  padding: 10.5px;
  cursor: pointer;
  border-radius: var(--border-radius-md);
  background-color: var(--named-color-nepal-20);
}

.crossArrows {
  width: 15px;
  height: 15px;
  color: var(--named-color-navy-100);
}

.closeButton {
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  top: 0;
  right: 0;
  margin: 12px;
  padding: 10.5px;
  cursor: pointer;
  border-radius: var(--border-radius-md);
  background-color: var(--named-color-nepal-20);
  z-index: 100;
}

@media (width < 768px),
  (height < 768px) and (orientation: landscape),
  (width < 1023px), {
  .expandedImageContainer {
    position: unset;
  }

  .expandedOverlay {
    background-color: var(--named-color-nepal-80);
  }
}

@keyframes fade-in {
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}

@keyframes fade-out {
  from {
    opacity: 1;
  }

  to {
    opacity: 0;
  }
}
