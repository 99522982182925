.badge {
  display: inline-flex;
  align-items: center;
  padding: var(--spacing-xs) var(--spacing-xs);
  border: var(--border);
  border-radius: var(--border-radius-md);
  border-color: var(--named-color-white-100);
  color: var(--named-color-white-100);
  height: 24px;
}

.badge.withText {
  padding: var(--spacing-xs) var(--spacing-sm);
}

.badge.inProgress {
  background-color: var(--color-badge-in-progress);
}

.badge.completed {
  background-color: var(--color-badge-completed);
}

.badge.notStarted {
  background-color: var(--color-badge-not-started);
}

.badge.tag {
  background-color: var(--named-color-nepal-60);
}

.badge.premium {
  background-color: var(--color-badge-premium);
}

.text {
  display: inline-flex;
  align-items: center;
  justify-content: center;
}

.text.withIcon {
  margin-left: var(--spacing-xs);
}

.icon {
  width: 12px;
  height: 12px;
  display: inline-flex;
  align-items: center;
  justify-content: center;
}

.icon.dark {
  color: var(--named-color-navy-80);
}

.icon.attention {
  color: var(--color-attention);
  width: 16px;
  height: 16px;
}
