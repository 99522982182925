.container {
  box-sizing: border-box;
}

.container.closing {
  /* Will preserve Snackbar height if it's alone in the queue; at the same time will move qeueu if it's closing */
  overflow: visible;
  animation: shrinking 500ms cubic-bezier(0.19, 1, 0.22, 1) forwards;
}

@keyframes shrinking {
  0% {
    height: 100%;
  }

  100% {
    height: 0%;
    margin-bottom: calc(-1 * var(--spacing-sm));
  }
}
