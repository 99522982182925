.container {
  text-align: left;
  width: 100%;
}

.container.center {
  text-align: center;
}

.container.right {
  text-align: right;
}
