.userAndLanguageContainer {
  width: 100%;
  height: 80px;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  padding: 0 var(--spacing-lg);
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  z-index: var(--zindex-fixed);
  box-sizing: border-box;
}

.container { 
  height: 100vh;
  overflow-y: auto;
}

@media (width < 768px) {
  .languageSelector,
  .userCard  {
    display: none;
  }
  
  .userAndLanguageContainer{
    height: 88px;
  }
}

.languageSelector {
  margin: 0 var(--spacing-sm);
}

.userCard {
  margin-left: var(--spacing-sm);
}

/* Tablet's biggest width */
@media (width < 1380px) {
  .userAndLanguageContainer {
    padding: 0 36px;
  }
}

@media (width < 768px) {
  .userAndLanguageContainer {
    padding: 0 var(--spacing-md);
  }
}
