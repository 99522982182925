.container {
  background-color: var(--named-color-nepal-20);
  border: 1px solid var(--named-color-nepal-60);
  border-radius: var(--border-radius-md);
  width: 100%;
  position: relative;
  height: 100%;
  display: flex;
  flex-direction: column;
  padding: var(--spacing-sm);
}

.content {
  max-height: 100%;
  height: 100%;
  overflow-y: auto;
  padding: 5px;
  word-wrap: break-word;
  white-space: pre-wrap;
  font-family: var(--font-primary);
  font-size: var(--font-size-body);
  font-weight: var(--font-weight-medium);
  color: var(--color-text-dark);
  text-align: left;
}

.content::-webkit-scrollbar {
  width: 8px;
}

.content::-webkit-scrollbar-thumb {
  background-color: var(--named-color-nepal-60);
  border-radius: 4px;
}
