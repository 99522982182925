.container {
  width: 100%;
  height: 100%;
  min-width: 168px;
  min-height: 26px;
  max-width: 290px;
  max-height: 48px;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  gap: var(--spacing-sm);
  text-decoration: none;
}

.container.disabled {
  pointer-events: none;
}

.badgeContainer {
  display: block;
}
