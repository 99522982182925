.page {
  padding-bottom: var(--spacing-xl);
}

.header {
  padding: 50px;
  padding-bottom: 20px;
  display: flex;
  flex-flow: row nowrap;
  justify-content: space-between;
  align-items: center;
  gap: var(--spacing-sm);
}

.filter {
  flex: 0;
  min-width: 280px;
  margin-bottom: var(--spacing-lg);
  align-self: flex-start;
  max-height: 100%;
}

.search {
  flex: 0;
  flex-basis: 440px;
  height: 48px;
  display: flex;
}

.content {
  display: flex;
  padding: 0 50px;
}

.coursesList {
  flex: 1;
  display: flex;
  flex-direction: column;
  gap: var(--spacing-md);
}

.coursesListItems {
  margin: 0 20px;
  display: flex;
  flex-flow: row wrap;
  gap: var(--spacing-md);
}

.coursesListItems > div {
  min-width: var(--min-width-card);
  max-width: min(32vw, var(--max-width-course-card));
}

.pagination {
  align-self: flex-end;
  display: flex;
  justify-content: flex-end;
}

.noCourseContainer {
  flex: 1;
  height: auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  text-align: center;
  color: var(--color-text-discrete);
}

.notFoundBySearchCourseContainer {
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: auto;
  width: 100%;
  text-align: center;
  color: var(--color-text-discrete);
}

.noCourseContainer span {
  text-align: center;
}

/* Tablet's biggest width */
@media (width < 1380px) {
  .header {
    padding: 36px 36px var(--spacing-md);
  }

  .content {
    padding: 0 36px;
  }

  .coursesListItems {
    margin-right: 0;
  }

  .coursesListItems > div {
    min-width: unset;
    max-width: calc(50% - (var(--spacing-md) / 2));
  }
}

.filterBarButton {
  display: none;
  align-items: center;
  justify-content: space-between;
  position: relative;
  width: 100%;
  height: 40px;
  border: 1px solid var(--named-color-nepal-60);
  border-radius: var(--border-radius-md);
  padding: var(--spacing-sm);
  background-color: var(--named-color-white-100);
}

.titlesContainer {
  display: flex;
  align-items: center;
  gap: 10px;
  flex-grow: 1;
}

.expandIcon {
  color: var(--named-color-navy-60);
}

/* Tablet portrait */
@media (width >= 768px) and (width < 1024px) and (orientation: portrait) {
  .content {
    max-width: unset;
    flex-direction: column;
  }

  .coursesListItems {
    margin: 0;
  }
}

@media (width < 768px) {
  .search {
    flex-basis: unset;
  }

  .filter {
    display: none;
  }

  .filterBarButton {
    display: flex;
  }

  .content {
    max-width: unset;
    flex-direction: column;
  }

  .coursesListItems {
    max-width: unset;
    margin: 0;
    flex-direction: column;
    align-items: stretch;
  }

  .coursesListItems > div {
    max-width: unset;
  }

  .content,
  .header {
    padding: 0 16px;
  }

  .header {
    flex-flow: column nowrap;
    align-items: stretch;
    padding-top: 24px;
    padding-bottom: 20px;
  }
}
