.clickable:not(.disabled) {
  cursor: pointer;
  transition-duration: 95ms;
  transition-timing-function: ease-out;
}

.clickable:hover:not(.disabled) {
  transform: translateY(-1.4px);
}

.clickable:active:not(.disabled) {
  transform: translateY(0.6px);
  transition-duration: 35ms;
  transition-timing-function: ease-in;
}

.disabled {
  opacity: 0.5;
}
