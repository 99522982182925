.menuOption {
  display: flex;
  align-items: center;
  padding: 10px 20px;
  border: var(--border);
  width: 100%;
  height: 48px;
  background-color: var(--named-color-white-100);
  color: var(--color-text);
  text-align: left;
  cursor: pointer;
  transition-duration: 175ms;
}

.menuOption.selected {
  background-color: var(--color-button-bare-onclick-bg);
  color: var(--color-attention);
  cursor: default;
}

.menuOption:not(.selected):hover {
  background-color: var(--color-button-bare-hover-bg);
  transition-duration: 120ms;
}

.menuOption.first {
  border-top-left-radius: var(--border-radius-lg);
  border-top-right-radius: var(--border-radius-lg);
}

.menuOption.last {
  border-bottom-left-radius: var(--border-radius-lg);
  border-bottom-right-radius: var(--border-radius-lg);
}

.text {
  margin-left: var(--spacing-sm);
  gap: var(--spacing-sm);
  display: flex;
  align-items: center;
  line-height: 1;
  transition-duration: 175ms;
}

.menuOption:not(.selected):hover .text {
  transform: translateX(3px);
  transition-duration: 120ms;
}
