.wrapper {
  position: relative;
  cursor: pointer;
  display: inline-block;
}

.container {
  padding: var(--spacing-sm);
  background-color: var(--named-color-white-100);
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: var(--border-radius-lg);
  height: var(--height-card-small);
  width: var(--height-card-small);
}

.container.bordered {
  border: var(--border-thick);
}

.container.expanded {
  background-color: var(--color-button-bare-onclick-bg);
}

.flag {
  width: 24px;
  height: 24px;
  display: block;
}

.staticMenu {
  position: absolute;
  top: calc(100% + 4px);
  right: 0;
  background: var(--named-color-white-100);
  border-radius: var(--border-radius-lg);
  box-shadow: var(--shadow-md);
  display: flex;
  flex-direction: column;
  min-width: 200px;
  z-index: var(--zindex-dropdown);
}

.staticMenu.up {
  top: -210%;
  left: 0;
}

@media (width < 576px) {
  .staticMenu {
    right: unset;
    left: 0;
  }
}

@media (width < 840px) {
  .staticMenu.up {
    left: unset;
    right: 0;
  }
}
