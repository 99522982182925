.container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  max-width: 600px;
  width: 600px;
}

.childContainer {
  position: relative;
  padding: var(--spacing-md) var(--spacing-md) var(--spacing-sm);
}

.button {
  text-align: center;
  width: 100%;
  margin-top: var(--spacing-sm);
}

.firstButton {
  margin-top: var(--spacing-md);
}

.loaderIcon {
  width: 20px;
  height: 20px;
  padding-right: 2px;
  align-items: center;
  animation: var(--animation-infinite-slow-rotation);
}

@media (width <= 768px) {
  .container {
    padding-top: 100px;
    justify-content: start;
    width: 70%;
  }
}

@media (width < 576px) {
  .container {
    width: 100%;
    padding: 100px var(--spacing-md) 0;
  }
}
