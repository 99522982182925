:root {
  /* Named colors */
  --named-color-navy-150: #000d19;
  --named-color-navy-100: #001932;
  --named-color-navy-80: #33475b;
  --named-color-navy-60: #667584;
  --named-color-navy-40: #99a3ad;
  --named-color-navy-20: #ccd1d6;
  --named-color-navy-10: #e6e8eb;
  --named-color-blue-150: #002966;
  --named-color-blue-120: #003d99;
  --named-color-blue-100: #0052cc;
  --named-color-blue-80: #3375d6;
  --named-color-blue-40: #99baeb;
  --named-color-blue-20: #ccdcf5;
  --named-color-nepal-100: #89a3be;
  --named-color-nepal-80: #a1b5cb;
  --named-color-nepal-60: #b8c8d8;
  --named-color-nepal-40: #d0dae5;
  --named-color-nepal-20: #e7edf2;
  --named-color-nepal-10: #f3f6f8;
  --named-color-nepal-5: #f9fafc;
  --named-color-green-150: #00432d;
  --named-color-green-100: #00875a;
  --named-color-green-80: #339f7b;
  --named-color-green-60: #66b79c;
  --named-color-green-40: #99cfbd;
  --named-color-grey-100: #8f92a1;
  --named-color-grey-transparent: #8f92a199;
  --named-color-yellow-150: #805f00;
  --named-color-yellow-120: #bf8f00;
  --named-color-yellow-100: #ffbe00;
  --named-color-yellow-20: #fff2cc;
  --named-color-red-100: #de350b;
  --named-color-red-120: #a72808;
  --named-color-black-40: #a2a2a2;
  --named-color-black-5: #f3f3f3;
  --named-color-lightgrey-120: #e9eef2;
  --named-color-lightgrey-80: #f5f8f9;
  --named-color-white-100: #fff;
  --named-color-white-transparent-50: #ffffff80;
  --named-color-light-blue-green: #73febb;

  /* Colors */
  --color-accent: var(--named-color-blue-100);
  --color-background: var(--named-color-lightgrey-80);
  --color-background-dark: var(--named-color-nepal-40);
  --color-background-light: var(--named-color-nepal-5);
  --color-background-overlay: var(--named-color-grey-transparent);
  --color-badge-in-progress: var(--named-color-yellow-100);
  --color-badge-completed: var(--named-color-green-80);
  --color-badge-not-started: var(--named-color-white-100);
  --color-badge-premium: var(--named-color-white-100);
  --color-badge-academy: var(--named-color-navy-100);
  --color-border: var(--named-color-nepal-20);
  --color-button-primary-bg: var(--named-color-yellow-100);
  --color-button-primary-text: var(--named-color-navy-100);
  --color-button-primary-hover-bg: var(--named-color-yellow-120);
  --color-button-primary-hover-text: var(--named-color-white-100);
  --color-button-primary-onclick-bg: var(--named-color-yellow-150);
  --color-button-primary-onclick-text: var(--named-color-white-100);
  --color-button-primary-disabled-bg: var(--named-color-yellow-20);
  --color-button-primary-disabled-text: var(--named-color-navy-20);
  --color-button-secondary-bg: var(--named-color-nepal-20);
  --color-button-secondary-text: var(--named-color-navy-80);
  --color-button-secondary-hover-bg: var(--named-color-nepal-40);
  --color-button-secondary-hover-text: var(--named-color-navy-80);
  --color-button-secondary-onclick-bg: var(--named-color-nepal-60);
  --color-button-secondary-onclick-text: var(--named-color-navy-80);
  --color-button-secondary-disabled-bg: var(--named-color-nepal-10);
  --color-button-secondary-disabled-text: var(--named-navy-40);
  --color-button-bare-bg: none;
  --color-button-bare-text: var(--named-color-navy-80);
  --color-button-bare-hover-bg: var(--named-color-lightgrey-80);
  --color-button-bare-hover-text: var(--named-color-navy-80);
  --color-button-bare-onclick-bg: var(--named-color-lightgrey-120);
  --color-button-bare-onclick-text: var(--named-color-navy-80);
  --color-button-bare-disabled-bg: none;
  --color-button-bare-disabled-text: var(--named-color-navy-10);
  --color-button-menu-bg: var(--named-color-white-100);
  --color-button-menu-text: var(--named-color-navy-80);
  --color-button-menu-hover-bg: var(--named-color-lightgrey-80);
  --color-button-menu-hover-text: var(--named-color-navy-80);
  --color-button-menu-onclick-bg: var(--named-color-lightgrey-120);
  --color-button-menu-onclick-text: var(--named-color-navy-80);
  --color-button-menu-disabled-bg: var(--named-color-nepal-10);
  --color-button-menu-disabled-text: var(--named-navy-40);
  --color-button-attention-text: var(--named-color-blue-100);
  --color-button-attention-text-light: var(--named-color-blue-40);
  --color-attention: var(--named-color-blue-100);
  --color-danger: var(--named-color-red-100);
  --color-divider: var(--named-color-nepal-80);
  --color-email: var(--named-color-blue-100);
  --color-link: var(--named-color-blue-100);
  --color-link-hover: var(--named-color-blue-120);
  --color-link-onclick: var(--named-color-blue-150);
  --color-link-disabled: var(--named-color-blue-40);
  --color-success-dark: var(--named-color-green-100);
  --color-success: var(--named-color-green-60);
  --color-text: var(--named-color-navy-80);
  --color-text-dark: var(--named-color-navy-100);
  --color-text-discrete: var(--named-color-navy-60);
  --color-text-help: var(--named-color-black-40);
  --color-text-help-dark: var(--named-color-navy-10);
  --color-text-complementary: var(--named-color-white-100);
  --color-text-highlight: var(--named-color-yellow-100);
  --color-warning: var(--named-color-red-100);
  --color-backdrop-bg: var(--named-color-lightgrey-120);
  --color-disabled-overlay: var(--named-color-white-transparent-50);

  /* Typography */
  --font-primary: 'DM Sans', arial, sans-serif;
  --font-secondary: 'Georgia', serif;
  --font-line-height: 1.5;
  --font-size-h1: 32px;
  --font-size-h2: 24px;
  --font-size-h3: 18px;
  --font-size-title-large: 14px;
  --font-size-title-small: 12px;
  --font-size-body-large: 16px;
  --font-size-body: 14px;
  --font-size-label: 12px;
  --font-weight-regular: 400;
  --font-weight-medium: 500;
  --font-weight-bold: 700;

  /* Heights */
  --height-card-small: 48px;
  --height-card-image: 200px;

  /* Widths */
  --min-width-card: 300px;
  --max-width-course-card: 318px;
  --width-video: 640px;
  --min-width-user-card: 190px;
  --max-width-user-card: 360px;

  /* Spacing */
  --spacing-xs: 0.25rem;
  --spacing-sm: 0.5rem;
  --spacing-md: 1rem;
  --spacing-lg: 1.5rem;
  --spacing-xl: 2rem;
  --spacing-xxl: 3rem;

  /* Borders */
  --border-radius-none: 0;
  --border-radius-sm: 6px;
  --border-radius-md: 8px;
  --border-radius-lg: 16px;
  --border-radius-circle: 50%;
  --border: 1px solid var(--color-border);
  --border-thick: 2px solid var(--color-border);

  /* Shadows */
  --shadow-sm: 0 1px 3px rgba(0 0 0 / 12%), 0 1px 2px rgba(0 0 0 /24%);
  --shadow-md: 0 4px 6px rgba(0 0 0 / 10%), 0 2px 4px rgba(0 0 0 / 6%);
  --shadow-lg: 0 10px 15px rgba(0 0 0 / 10%), 0 4px 6px rgba(0 0 0 / 5%);
  --shadow-xl: 0 20px 25px rgba(0 0 0 / 10%), 0 10px 10px rgba(0 0 0 / 4%);

  /* Breakpoints */
  --breakpoint-xs: 0;
  --breakpoint-sm: 576px;
  --breakpoint-md: 768px;
  --breakpoint-lg: 992px;
  --breakpoint-xl: 1200px;

  /* Z-Index */
  --zindex-dropdown: 1000;
  --zindex-sticky: 1020;
  --zindex-fixed: 1030;
  --zindex-modal-backdrop: 1040;
  --zindex-modal: 1050;
  --zindex-popover: 1060;
  --zindex-tooltip: 1070;

  /* Transitions */
  --transition-fast: 0.2s;
  --transition-normal: 0.5s;
  --transition-slow: 1s;

  /* Others */
  --opacity-disabled: 0.4;

  /* Animations */
  --animation-infinite-fast-rotation: rotation var(--transition-fast) linear
    infinite;
  --animation-infinite-rotation: rotation var(--transition-normal) linear
    infinite;
  --animation-infinite-slow-rotation: rotation var(--transition-slow) linear
    infinite;
}

@keyframes rotation {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}
