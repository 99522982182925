.paginationControls {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  gap: 6px;
}

.icon {
  width: 21px;
  height: 21px;
  padding-top: 5px;
}
