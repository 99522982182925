.containerContent {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  max-width: 500px;
  min-width: 300px;
  margin-top: var(--spacing-md);
  gap: 12px;
  padding: 0 var(--spacing-md);
}

.centeredContent {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
}

.buttonsContainer {
  display: flex;
  flex-direction: row;
  gap: 12px;
  height: 57px;
}

.customButtonContainer {
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  padding: 0 var(--spacing-md);
}

.customButtonContent {
  display: flex;
  flex-direction: column;
  gap: 5px;
  align-items: flex-start;
}

.customButtonIconContainer {
  align-content: center;
}

.notFoundIllustration {
  max-width: 100%;
  height: auto;
}

@media (width < 576px) {
  .buttonsContainer {
    flex-direction: column-reverse;
    height: auto;
    gap: 8px;
  }

  .buttonsContainer button:first-child {
    height: 48px;
  }

  .customButtonContainer {
    height: 57px;
    align-items: center;
    justify-content: space-between;
  }
}
